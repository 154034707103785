<template>
  <div>
    <b-row>
      <b-col v-if="!forSummary" cols="12" class="mt-1 mb-2">
        <!-- <h4 class="mb-1 h4 text-primary">
          {{ $t("Confirm Mint Title") }}
        </h4> -->
        <h5 class="mb-1 h4 text-primary">
          {{ $t("Confirm Mint Subtitle") }}
        </h5>
      </b-col>

      <b-col cols="6">
        <b-form-group
          :label="$t('total_nft_available_for_allocation')"
          label-for="est-value-all-nft-supply"
        >
          <span id="est-value-all-nft-supply" class="h5 text-uppercase">
            {{
              formatMoney(
                nftFormData && nftFormData.supply ? nftFormData.supply : 0
              )
            }}
          </span>
        </b-form-group>
      </b-col>

      <b-col cols="6">
        <b-form-group
          :label="$t('NFT Contract Type')"
          label-for="est-value-all-nft-contract_type"
        >
          <span id="est-value-all-nft-contract_type" class="h5 text-uppercase">
            {{ nftFormData && nftFormData.contract_type }}
          </span>
        </b-form-group>
      </b-col>

      <b-col cols="6">
        <b-form-group
          :label="$t('Estimated Total Value')"
          label-for="est-value-all-nft"
        >
          <span id="est-value-all-nft" class="h5 text-uppercase">
            {{ $t("currencySign") }}
            {{ formatMoney(estTotalValue) }}
          </span>
        </b-form-group>
      </b-col>

      <b-col cols="6">
        <b-form-group :label="$t('Lease Amount')" label-for="v-lease-amount">
          <span class="h5">
            {{ $t("currencySign") }}
            {{ amount }}
            /
            {{ freq }}
          </span>
        </b-form-group>
      </b-col>

      <b-col cols="12" class="mb-2">
        <b-list-group>
          <b-list-group-item
            v-for="(entity, index) in allocations"
            :id="entity.name"
            :key="index"
            ref="row"
            class="flex-column align-items-start"
            v-show="entity.allocation > 0"
          >
            <div class="row d-flex justify-content-between">
              <h5 class="mb-1 text-primary text-uppercase">
                <span v-if="entity.act_as != 'creator'">
                  {{ $t(entity.act_as) }}
                </span>
                <span v-if="entity.act_as == 'creator'">
                  {{ $t("Lease Creator") }}
                </span>
              </h5>
              <small class="text-secondary h6">
                {{ entity.fullname }}
              </small>
            </div>

            <div class="row d-flex justify-content-between">
              <b-form-group
                :label="$t('Allocation')"
                :label-for="'select_nft_allocation_ent_' + entity.name"
              >
                <span>
                  {{ formatMoney(entity.allocation) }}
                  ( {{ allocationPercent(entity) }}% )
                </span>
              </b-form-group>
              <b-form-group
                :label="$t('Email')"
                :label-for="'select_nft_allocation_ent_' + entity.name"
              >
                <span>
                  {{ entity.email }}
                </span>
              </b-form-group>
            </div>
          </b-list-group-item>
        </b-list-group>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import "vue-form-wizard/dist/vue-form-wizard.min.css";
import Ripple from "vue-ripple-directive";
import {
  BButton,
  BRow,
  BCol,
  BFormGroup,
  BFormInput,
  BFormSelect,
  BFormTextarea,
  BCardText,
  BCard,
  BListGroup,
  BListGroupItem,
  BButtonGroup,
} from "bootstrap-vue";

import { getNameFromFileObj, timeToGo, showInfoModal } from "@/libs/helpers";

import FormEntitiesSummary from "../form-entities/FormEntitiesSummary";
import FormLeaseTermsSummary from "@/views/forms/components/form-leases/FormLeaseTermsSummary";

import {
  estLeaseValue,
  dollarUSLocale,
  estValuePerNft,
  estIncomePerNftPerFreq,
  leaseDaysLeft,
} from "@/libs/financeHelper";

import Cleave from "vue-cleave-component";

export default {
  directives: {
    Ripple,
  },
  components: {
    BFormSelect,
    BFormTextarea,
    BCardText,
    BListGroup,
    BListGroupItem,

    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    BButton,
    BCard,
    BCardText,
    BButtonGroup,

    Cleave,

    FormEntitiesSummary,
    FormLeaseTermsSummary,
  },
  props: {
    forSummary: {
      type: Boolean,
      default: false,
    },
    allocations: {
      type: Array,
      required: true,
    },
    nftFormData: {
      type: Object,
      // required: true,
    },
    lease: {
      type: Object,
      default: null,
    },
  },
  watch: {},
  data() {
    return {};
  },
  mounted() {},
  computed: {
    supply() {
      return this.nftFormData && this.nftFormData.supply
        ? this.nftFormData.supply
        : 0;
    },
    entities() {
      return this.lease ? this.lease.entities : [];
    },
    showNftIncomePerFreq() {
      return estIncomePerNftPerFreq(
        this.showValuePerNft,
        this.daysLeft,
        this.freq
      );
    },
    freq() {
      return this.lease && this.lease.paym_freq ? this.lease.paym_freq : "";
    },
    amount() {
      return this.lease && this.lease.paym_freq_amt
        ? this.formatMoney(this.lease.paym_freq_amt)
        : 0;
    },
    daysLeft() {
      if (!this.lease || !this.lease.start_date) return 0;
      return leaseDaysLeft(this.lease);
    },
    leaseTimeLeft() {
      if (this.lease && this.lease.start_date && this.lease.end_date) {
        return timeToGo(this.lease.start_date, this.lease.end_date);
      } else {
        return "";
      }
    },
    estTotalValue() {
      if (this.lease) return estLeaseValue(this.lease);
      else return 0;
    },
    showValuePerNft() {
      // console.log("supply : ", this.nftFormData.supply);
      return estValuePerNft(this.estTotalValue, this.supply);
    },
  },
  methods: {
    allocationPercent(entity) {
      return Math.round((entity.allocation * 10000) / this.supply) / 100;
    },
    showValuePerAlloc(entity) {
      return (
        entity.allocation * estValuePerNft(this.estTotalValue, this.supply)
      );
    },
    showAllocIncomePerFreq(entity) {
      return (
        entity.allocation *
        estIncomePerNftPerFreq(this.showValuePerNft, this.daysLeft, this.freq)
      );
    },
    assignAllocation(entity, option) {
      // console.log("assign allocation: ", entity, option);
      entity.allocation = this.supply * option.percent;
      // this.$emit("input");
      let elem = document.getElementById(
        "select_nft_allocation_ent_" + entity.name
      );
      // elem.value = entity.allication;
      // console.log("dispatch", elem);
      elem.dispatchEvent(new Event("input"));
      // this.$set(entity, "allocation", this.supply * option.percent);
    },
    formatMoney(amt) {
      return dollarUSLocale.format(amt);
    },
    getNameFromFileObj: function (fname) {
      return getNameFromFileObj(fname);
    },

    removeTimeFromDate: function (timeStr) {
      let dd = new Date(Date.parse(timeStr));
      return dd.getFullYear() + "-" + dd.getMonth() + "-" + dd.getDate();
    },
  },
};
</script>
