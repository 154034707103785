<template>
  <b-row>
    <b-col cols="12">
      <h1 class="mb-1 h3 text-primary">
        {{ $t("signature_fullname_header") }}
      </h1>

      <h2 class="mb-3 h5 text-secondary">{{ $t("signature_disclaimer") }}</h2>

      <b-form-group :label="entityFullName" label-for="largeInput">
        <p class="text-uppercase pb-1">{{ $t("signature") }}</p>
        <b-form-input
          v-model="formData.signature"
          id="largeInput"
          size="lg"
          :placeholder="$t('Full Name Here')"
          required
        />
      </b-form-group>
    </b-col>
  </b-row>
</template>

<script>
import "vue-form-wizard/dist/vue-form-wizard.min.css";
import Ripple from "vue-ripple-directive";
import {
  BButton,
  BRow,
  BCol,
  BFormGroup,
  BFormInput,
  BFormSelect,
  BFormTextarea,
  BCardText,
  BCard,
  BListGroup,
  BListGroupItem,
} from "bootstrap-vue";

export default {
  directives: {
    Ripple,
  },
  components: {
    BFormSelect,
    BFormTextarea,
    BCardText,
    BListGroup,
    BListGroupItem,

    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    BButton,
    BCard,
    BCardText,
  },
  props: {
    entityFullName: {
      type: String,
      default: "",
    },
    formData: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {};
  },
  computed: {},
  methods: {},
};
</script>
