<template>
  <b-row>
    <b-col cols="12">
      <form-wizard
        color="#7367F0"
        :title="null"
        :subtitle="null"
        layout="vertical"
        finish-button-text="Finish"
        back-button-text="Back"
        class="wizard-vertical mb-3"
        @on-complete="formSubmitted"
        @on-change="stepChanged"
      >
        <!-- intro tab -->
        <tab-content :title="$t('Introduction')">
          <!-- <h1 class="h5 text-muted">{{ $t("mint_nft_sign_intro_title") }}</h1> -->
          <span class="h5">
            <p>
              Lease (address below) was converted to NFT (similar to shares) on
              <span class="text-primary">{{ appName }}</span>
              (<span class="">{{ nftCreatedTime }}).</span>

              You were named in the lease contract as a
              <span class="text-uppercase text-primary">{{
                entity ? entity.act_as : ""
              }}</span>
            </p>
            <p>
              <h1 class="h5 text-muted">{{ $t("mint_nft_sign_intro_title") }}</h1>
               <ol>
                <li>Please review the Lease Contract.</li>
                <li>Please review the NFT Agreement and understand your rights and obiligations.</li>
                <li>Digitally sign the NFT Agreement. </li>
              </ol>
            </p>
            <p>
              Note: all relevant stakeholders of a Lease contract must sign before NFT is live. 
            </p>

            <p class="d-flex justify-content-between mt-2">
              <span>Address: </span>
              <span class="h4 ml-2">{{ lease_address }}</span>
            </p>
          </span>
        </tab-content>

        <!-- intro tab -->
        <tab-content :title="$t('Review Lease')">
          <form-mint-lease-summary :show_card="false" :lease="lease" />
        </tab-content>

        <!-- intro tab -->
        <tab-content :title="$t('Review NFT')">
          <form-mint-nft-confirm
          :lease="lease"
          :nftFormData="nft"
          :allocations="[]"
          :forSummary=true
        />
        </tab-content>

        <!-- intro tab -->
        <tab-content :title="$t('NFT Agreement')">
          <b-row>
          <b-col cols="6">
            <b-form-group
              :label="$t('NFT Contract Type')"
              label-for="typeofcontract_sign"
            >
              <span class="h5 text-uppercase">
                {{ nft && nft.contract_type ? nft.contract_type: '' }}
              </span>
            </b-form-group>
          </b-col>
          <b-col cols="12">
            <div class='embed-responsive' style='padding-bottom:150%'>
                <object data='http://www.africau.edu/images/default/sample.pdf' type='application/pdf' width='100%' height='60%'></object>
            </div>
          </b-col>
          </b-row>

        </tab-content>

        <!-- intro tab -->
        <tab-content :title="$t('Signature')">
          <form-signature 
          :entityFullName="entity && entity.fullname ? entity.fullname : ''"
          :formData="signNftFormData" />
        </tab-content>
      </form-wizard>
    </b-col>
  </b-row>
</template>

<script>
import { FormWizard, TabContent } from "vue-form-wizard";

import vSelect from "vue-select";
import "vue-form-wizard/dist/vue-form-wizard.min.css";

import entity from "@/libs/struct/entity";
import address from "@/libs/struct/address";
import { cloneStruct } from "@core/utils/utils";
import { saveToLocal, getLocal, updateArrElemById } from "@/libs/helpers";

import FormAddress from "@/views/forms/components/form-address/FormAddress";
import FormLeaseTerms from "@/views/forms/components/form-leases/FormLeaseTerms";
import FormEntities from "@/views/forms/components/form-entities/FormEntities";
import FormUploadLeaseDocs from "@/views/forms/components/form-leases/FormUploadLeaseDocs.vue";

import FormLeaseConfirm from "@/views/forms/components/form-leases/FormLeaseConfirm";
import FormMintLeaseSummary from "@/views/forms/components/form-mint/FormMintLeaseSummary";
import FormMintNftConfirm from "@/views/forms/components/form-mint/FormMintNftConfirm";
import FormSignature from "@/views/forms/components/form-mint/FormSignature";

import Ripple from "vue-ripple-directive";

import laravel from "@/libs/laravel";

import {
  BButton,
  BRow,
  BCol,
  BFormGroup,
  BFormInput,
  BFormSelect,
  BFormTextarea,
  BFormDatepicker,
  BCardText,
} from "bootstrap-vue";

import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import DragDropCloneVue from "@/views/extensions/drag-drop/DragDropClone.vue";
import { sync } from "vuex-pathify";
import { getArrElemById, sortEntitiesArrayByType } from "@/libs/helpers";
import { $themeConfig } from "@themeConfig";

let signData = {
  signature: "",
};

export default {
  directives: {
    Ripple,
  },
  components: {
    FormAddress,
    FormEntities,
    FormLeaseTerms,
    FormUploadLeaseDocs,
    FormLeaseConfirm,
    FormMintLeaseSummary,
    FormMintNftConfirm,
    FormSignature,

    BFormSelect,
    BFormTextarea,
    BCardText,
    BFormDatepicker,

    FormWizard,
    TabContent,

    BButton,
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    vSelect,
    // eslint-disable-next-line vue/no-unused-components
    ToastificationContent,
  },
  data() {
    return {
      appName: $themeConfig.app.appNameUrl,
      signNftFormData: signData,
      entity: null,
      nft: null,
      lease: null,
      disableSubmit: false,
    };
  },
  props: {},
  computed: {
    user: sync("user/profile"),
    leases: sync("leases/list"),

    nftCreatedTime() {
      let tm = this.nft ? this.nft.created_at : "";
      if (tm) {
        let dt = new Date(tm);
        return dt.toLocaleDateString();
      } else {
        return tm;
      }
    },
    nftId() {
      // console.log("leaseid: ", this.$router);
      let id = parseInt(this.$route.params.nftId);
      if (id) {
        return id;
      } else {
        // new lease
        return -1;
      }
    },
    entityId() {
      // console.log("leaseid: ", this.$router);
      let id = parseInt(this.$route.params.entityId);
      if (id) {
        return id;
      } else {
        // new lease
        return -1;
      }
    },
    lease_address() {
      console.log("get lease_address");
      if (this.entity && this.entity.lease && this.entity.lease.address) {
        console.log("get lease_address: " + this.entity.lease.address);
        return this.entity.lease.address;
      } else {
        return "";
      }
    },
  },
  mounted() {
    console.log("entityId:", this.entityId, " nftId:", this.nftId);

    if (this.nftId > 0 && this.nftId > 0) {
      laravel.nfts
        .show(this.nftId)
        .then((resp) => {
          console.log("found nft: ", resp);
          this.nft = resp.data;
          this.nft.contract_type = this.nft.contracts[0].type;

          // the logged in user should be the entity user
          laravel.entities
            .show(this.entityId)
            .then((resp) => {
              console.log("ent found: ", resp);
              this.entity = resp.data;

              // the logged in user should be the entity user
              laravel.leases
                .show(this.entity.lease.id)
                .then((resp) => {
                  console.log("lease found: ", resp);
                  this.lease = JSON.parse(JSON.stringify(resp.data));
                  let sortedArrs = sortEntitiesArrayByType(this.lease.entities);
                  this.lease.leasors = sortedArrs.leasors;
                  this.lease.leasees = sortedArrs.leasees;
                  this.lease.guarantors = sortedArrs.guarantors;
                  this.lease.documents = this.lease.files;
                })
                .catch((error) => {
                  console.log("err: ", error);
                  this.$toast({
                    component: ToastificationContent,
                    props: {
                      title: "Lease record not found!",
                      icon: "EditIcon",
                      variant: "danger",
                    },
                  });
                });
            })
            .catch((err) => {
              console.log("err: ", err);
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: "Entity not found!",
                  icon: "EditIcon",
                  variant: "danger",
                },
              });
            });
        })
        .catch((error) => {
          console.log("Error: ", error.message);
          // route back toast then route back

          this.$toast({
            component: ToastificationContent,
            props: {
              title: "Nft not found!",
              icon: "EditIcon",
              variant: "danger",
            },
          });
          // setTimeout(() => this.$router.go(-1), 2000);
        });
    } else {
      console.log("invalid parameters");
      this.$toast({
        component: ToastificationContent,
        props: {
          title: "Invalid parameters!",
          icon: "EditIcon",
          variant: "danger",
        },
      });
    }
  },
  methods: {
    resetForm() {
      // this.signFormData.lease = leaseInitData;
    },
    stepChanged(prevIndex, nextIndex) {
      // console.log("step changed: ", prevIndex, nextIndex);
      // saveToLocal("leaseFormData", this.leaseFormData);
    },

    formSubmitted() {
      console.log("user: ", this.user);
      console.log("signNftFormData:", this.signNftFormData);
      console.log("nft, entity:", this.nft, this.entity);
      if (this.disableSubmit) {
        // skip multiple clicks
        return;
      }

      this.disableSubmit = true;
      laravel.nfts
        .sign(this.nft.id, this.entity.id, this.signNftFormData)
        .then((resp) => {
          console.log("success sign: ", resp);
          this.$toast({
            component: ToastificationContent,
            props: {
              title: "Nft agrement signed!",
              icon: "EditIcon",
              variant: "success",
            },
          });
          this.disableSubmit = false;
          this.$router.push({ name: "manage-leases" });
        })
        .catch((error) => {
          console.log("error sign: ", error);
          this.$toast({
            component: ToastificationContent,
            props: {
              title: "Signature submit failed!",
              icon: "EditIcon",
              variant: "danger",
            },
          });
          this.disableSubmit = false;
        });
    },
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-wizard.scss";
@import "@core/scss/vue/libs/vue-select.scss";
</style>
